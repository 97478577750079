<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Log in
			</div>
			<V2Button to="/create-account">
				Create an account
			</V2Button>
		</div>

		<div class="">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Email address"
					type="email"
					autocomplete="username"
					rules="email|required"
					v-model="credentials.email"
				/>
				<input-field
					name="Password"
					type="password"
					autocomplete="current-password"
					rules="required"
					v-model="credentials.password"
				/>
				<actions :actions="actions" />
			</validation-observer>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublicOnly": true
		}
	}
</route>

<script>

	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import V2Button            from '@/components/v2/ui/V2Button';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Log in'
		},
		components: {
			InputField,
			Actions,
			V2Button
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			credentials: {
				email: '',
				password: ''
			},
			pageHeaderAction: {
				text: 'Create an account',
				route: '/create-account'
			},
			actions: {
				primary: [
					{
						text: 'Log in',
						type: 'submit'
					}
				],
				secondary: [
					{
						text: 'Reset password',
						route: '/reset-password'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/login',
						text: 'Log in'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				await this.$store.dispatch('auth/logIn', {
					credentials: this.credentials
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      font-size: 46px;
      margin-bottom: 24px;
    }

    .btn {
      width: auto;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 12px;
    }
  }

}

</style>
